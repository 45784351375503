import { render, staticRenderFns } from "./EditAward.vue?vue&type=template&id=78fcb7ff&scoped=true"
import script from "./EditAward.vue?vue&type=script&lang=js"
export * from "./EditAward.vue?vue&type=script&lang=js"
import style0 from "./EditAward.vue?vue&type=style&index=0&id=78fcb7ff&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "78fcb7ff",
  null
  
)

export default component.exports