<template>
  <aside>
    <header>
      <h2>Edit Award</h2>
      <button class="close" @click="close">
        <span class="sr-only">Close modal window</span>
      </button>
    </header>

    <main>
      <div class="setting-row">
        <div class="title">
          <h4>Name</h4>
        </div>
        <div class="fields">
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.name.$error }"
            v-model="$v.name.$model"
          />
          <div class="invalid-feedback">Award name is required.</div>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Hide from managers</h4>
          <p>Turn on to hide this award from the spot award menu</p>
        </div>
        <div class="fields">
          <ht-switch v-model="award.is_hidden" />
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Restrict to segments</h4>
          <p>Make this award visible to only managers of specific segments</p>
        </div>
        <div class="fields">
          <v-select
            v-model="for_segments"
            :options="segments"
            label="name"
            placeholder="Select segments..."
            :multiple="true"
            :reduce="(opt) => opt.id"
          />
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Description</h4>
          <p>What this award represents and how/why it's earned.</p>
        </div>
        <div class="fields">
          <textarea
            class="form-control"
            :class="{ 'is-invalid': $v.description.$error }"
            v-model="$v.description.$model"
          ></textarea>
          <div class="invalid-feedback">Award description is required.</div>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Points</h4>
          <p>Amount of points a user earns when they receive this award.</p>
        </div>
        <div class="fields">
          <input
            type="text"
            class="form-control"
            :class="{ 'is-invalid': $v.points.$error }"
            v-model.number="$v.points.$model"
          />
          <div class="invalid-feedback">
            Award points is required, can be 0.
          </div>

          <span class="help-text">{{ $conversionRate($v.points.$model) }}</span>
        </div>
      </div>

      <div class="setting-row">
        <div class="title">
          <h4>Banner design</h4>
          <p>
            Customize the award banner that is attached to messages in Slack.
          </p>
        </div>
        <div class="fields">
          <div class="banner-preview" v-if="banner !== null">
            <img
              :src="
                `https://img-cdn.hithrive.com/teams-award-skins/${banner.award_card_id}.png`
              "
              height="80"
            />
          </div>
          <button
            class="btn btn-xs btn-light"
            :class="{ 'is-invalid': $v.banner.$error }"
            @click="selectBanner"
          >
            Select a banner
          </button>

          <div class="invalid-feedback">A banner is required.</div>
        </div>
      </div>
    </main>

    <footer>
      <router-link
        class="btn btn-sm btn-light mr-auto"
        @click.native="close"
        :to="{
          name: 'reporting-activity',
          query: { type: 'awards', award_id: award.id },
        }"
        ><i class="fas fa-chart-pie"></i> Latest activity</router-link
      >
      <button
        class="btn btn-link btn-muted-link btn-sm"
        :class="{ loading: loading }"
        @click="archive"
      >
        Archive
      </button>
      <button
        class="btn btn-sm btn-green"
        :class="{ loading: loading }"
        @click="save"
      >
        Save award
      </button>
    </footer>
  </aside>
</template>

<script>
import { required, numeric } from 'vuelidate/lib/validators';

export default {
  props: ['data'],
  computed: {
    loading() {
      return this.$store.state.loading;
    },
    segments() {
      return this.$store.state.segments;
    },
  },
  data() {
    return {
      award: null,
      name: '',
      description: '',
      points: 0,
      banner: null,
      for_segments: [],
    };
  },
  validations: {
    name: {
      required,
    },
    description: {
      required,
    },
    points: {
      numeric,
      required,
    },
    banner: {
      required,
    },
  },
  created() {
    this.award = _.cloneDeep(this.data);

    this.name = this.data.name;
    this.description = this.data.description;
    this.points = this.data.points;
    this.banner = this.data.award_skin;
    this.for_segments = this.data.for_segments;
  },
  methods: {
    close() {
      this.$emit('done');
    },
    async archive() {
      if (!confirm('Are you sure you want to archive this award?')) return;

      this.$store.commit('loading', true);
      await this.$api.Awards.archive(this.award.id);
      this.$store.commit('loading', false);

      this.$toast.success('Award has been archived successfully.');

      this.$emit('done', 'ARCHIVED_AWARD');
    },
    async save() {
      this.$v.$touch();
      if (this.$v.$invalid) return;

      this.$store.commit('loading', true);

      const award = await this.$api.Awards.update({
        id: this.award.id,
        name: this.name,
        description: this.description,
        award_skin: this.banner.award_card_id,
        points: this.points,
        is_hidden: this.award.is_hidden,
        for_segments: this.for_segments,
      });

      if (award.error) {
        this.$store.commit('loading', false);
        return;
      }

      this.$toast.success('Award saved successfully.');
      this.$store.commit('loading', false);

      this.$emit('done', 'SAVED_AWARD');
    },
    selectBanner() {
      this.$root.$emit(
        'openModal',
        'Awards/BannerSelectorSingle',
        {},
        (result, banner) => {
          if (result === 'BANNER_SELECTED') {
            this.$v.banner.$model = banner;
          }
        }
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.banner-preview {
  margin: 0 0 10px;
  > img {
    border-radius: 6px;
  }
}
</style>
